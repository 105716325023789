import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import qrcode from './qrcode.png';

const useStyles = makeStyles(theme => ({
  map: {
    boxShadow:
      '0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
    filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08))',
    borderRadius: '10px',
    border: 0,
    width: '100%',
    height: 300,
    [theme.breakpoints.up('md')]: {
      width: 500,
      height: 310,
    },
  },
  divider: {
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
  qrcode: {
    width: '100%',
    minHeight: '200px',
    [theme.breakpoints.up('md')]: {
      width: '75%',
    },
  },
  icon: {
    background: 'transparent',
    borderRadius: 0,
  },
  contactIcon: {
    color: theme.palette.secondary.main,
    marginRight: '3%',
    marginBottom: '6%',
  },
}));

const Information = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <div>
            <Grid container style={{ marginBottom: '26px' }}>
              <Grid item xs={1}>
                <i className={clsx('fas fa-phone-alt', classes.contactIcon)} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  align="left"
                  variant="body1"
                  style={{ marginLeft: -5 }}
                >
                  +852 2163 7688
                </Typography>
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: '26px' }}>
              <Grid item xs={1}>
                <i className={clsx('fas fa-envelope', classes.contactIcon)} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  align="left"
                  variant="body1"
                  style={{ marginLeft: -5 }}
                >
                  growth@thecenterspace.co
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.divider} />

            <Typography
              align="left"
              variant="h6"
              style={{ marginTop: '5%', marginBottom: '20px' }}
            >
              {t('contactVisitUs')}
            </Typography>

            <Grid container style={{ marginBottom: '26px' }}>
              <Grid item xs={1}>
                <i className={clsx('fa fa-map-marker', classes.contactIcon)} />
              </Grid>
              <Grid item xs={11}>
                <Typography
                  align="left"
                  variant="body1"
                  style={{ marginLeft: -5, width: '75%' }}
                >
                  {t('contactAddress')}
                </Typography>
              </Grid>
            </Grid>

            <Grid style={{ marginBottom: '26px', textAlign: 'center' }}>
              <div className={classes.qrcode}>
                <img src={qrcode} alt="qr code" width="250px" height="200px" />
              </div>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14767.591055879173!2d114.16046452622128!3d22.28186226532868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404007cbe420509%3A0x46f1ddac9a1e350f!2sThe%20Center!5e0!3m2!1sen!2sin!4v1625628802754!5m2!1sen!2sin"
            className={classes.map}
            loading="lazy"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Information;
